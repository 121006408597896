import React from "react"
import Markdown from "markdown-to-jsx"
import T from "~components/translator"
import { AlertBanner, AlertBannerIcon, AlertBannerHeading, AlertBannerText, AlertBannerCTA } from "@fastly/consistently"

const Alert = (props) => {
  const { tip, note, important, warning } = props
  const { children } = props

  if (children == null) {
    return <></>
  }

  let attributes = []
  attributes["variant"] = "recommendation" //assume base gray for any unspecified
  let auto_leader = false
  let auto_icon = false

  if (tip) {
    attributes["variant"] = "success" //alert-banner:green
    auto_leader = "tip"
    auto_icon = true
  }

  if (note) {
    attributes["variant"] = "information" //alert-banner:blue
    auto_leader = "note"
    auto_icon = true
  }

  if (important) {
    attributes["variant"] = "error" //alert-banner:red
    auto_leader = "important"
    auto_icon = true
  }

  if (warning) {
    attributes["variant"] = "warning" //alert-banner: yellow
    auto_leader = "warning"
    auto_icon = true
  }

  if ("leader" in props) {
    attributes["leader"] = props.leader
  }
  if ("header" in props && props.header == "false") {
    delete attributes["leader"]
    auto_leader = false
  }

  if (auto_leader && !("leader" in attributes)) {
    attributes["leader"] = <T>{`alert-leader-${auto_leader}`}</T>
  }

  if ("icon" in props && props.icon == "false") {
    auto_icon = false
  }
  var alert_body = ""

  if (typeof children === "string") {
    alert_body = <Markdown>{children}</Markdown>
  } else if (typeof children === "object" && !("length" in children)) {
    alert_body = children
  } else if (typeof children === "object" && "length" in children) {
    alert_body = []
    for (const [, value] of Object.entries(children)) {
      if (typeof value === "string") {
        alert_body.push(<Markdown>{value}</Markdown>)
      } else {
        alert_body.push(value)
      }
    }
  } else {
    // new parent mode, aka 'unknown how to handle children', figure this out later
  }

  return (
    <>
      <AlertBanner type={attributes["variant"]} marginBottom="1em">
        {auto_icon ? <AlertBannerIcon /> : ""}
        {attributes["leader"] ? <AlertBannerHeading>{attributes["leader"]}</AlertBannerHeading> : ""}
        <AlertBannerText>{alert_body}</AlertBannerText>
      </AlertBanner>
    </>
  )
}

export default Alert
