import React from "react"
import FrontmatterPageContext from "~context/FakePageContext"
import T from "~components/translator"
import Awesome from "~components/awesome"
import { Link } from "gatsby"

import { breadcrumbItemActive, breadcrumbsWrapper, breadcrumbItem } from "./Breadcrumbs.css"


export const Breadcrumbs = ({ children }) => {
  return (
    <nav className="d-print-none" aria-label="breadcrumb" data-swiftype-index="false">
      <ul className={breadcrumbsWrapper}>{children}</ul>
    </nav>
  )
}

export const BreadcrumbItem = ({ name, url, current }) => {
  return (
    <li className={current ? breadcrumbItemActive : breadcrumbItem} aria-current={current ? "page" : false}>
      {url ? <Link to={url}>{name}</Link> : name}
    </li>
  )
}

export const ComplexHomeText = () => {
  return (
    <>
      <span className="d-none d-sm-inline">
        <T>wellnav-home</T>
      </span>
      <span className="d-sm-none">
        <Awesome icon="home" />
      </span>
    </>
  )
}
export const BreadcrumbsGuides = () => {
  let page = React.useContext(FrontmatterPageContext)
  let crumb2text = <T>wellnav-guides</T>
  if ("crumb2override" in page) {
    crumb2text = page["crumb2override"]
  }

  //default
  let crumb3text = "📄"
  if ("section_name" in page) {
    // yes local override?
    crumb3text = page["section_name"]
  } else if ("myPrimary" in page) {
    //no local override, get it out of primary data
    crumb3text = page["myPrimary"]["frontmatter"]["section_name"]
  }

  return (
    <Breadcrumbs>
      <BreadcrumbItem name={<ComplexHomeText />} url={page["home_url"]} />
      <BreadcrumbItem name={crumb2text} url={page["section_url"]} />
      <BreadcrumbItem name={crumb3text} url={`${page["section_url"]}${page["primary_nav"]}`} current />
    </Breadcrumbs>
  )
}

export const BreadcrumbsPrimary = () => {
  let page = React.useContext(FrontmatterPageContext)

  let crumb2text = <T>wellnav-guides</T>
  if ("section" in page && page.section === "fundamentals") {
    crumb2text = <T>wellnav-fundamentals</T>
  }

  if ("crumb2override" in page) {
    crumb2text = page["crumb2override"]
  }

  return (
    <Breadcrumbs>
      <BreadcrumbItem name={<ComplexHomeText />} url={page["home_url"]} />
      <BreadcrumbItem name={crumb2text} url={page["section_url"]} />
    </Breadcrumbs>
  )
}
