import React from "react"

const mailto = ({ children }) => {
  let mail = null

  switch (typeof children) {
    case "string":
      mail = String(children)
      break

    case "object":
      if (children.hasOwnProperty("props")) {
        mail = String(children.props.children)
      }
      break
  }

  if (mail === null) {
    return <></>
  }

  return <a href={`mailto:${mail}`}>{mail}</a>
}

export default mailto
